const ru = {
  Add: "Добавить",
  IsStatic: "Статичный",
  HasCategory: "Категория",
  Delete: "Удалить",
  Edit: "Редактировать",
  goToSite: "Перейти на сайт",
  Link: "Ссылка",
  Type: "Тип",
  SelectType: "Выберите тип",
  Published: "Опубликован",
  NotPublished: "Не опубликовано",
  UsersColTitle: "Логин",
  UserTitle: "Пользователи",
  FormFullName: "Ф.И.О",
  FormFirstName: "Имя",
  FormFirstNamePlace: "Введите Ваше имя",
  FormLastName: "Фамилия",
  FormLastNamePlace: "Введите Вашу фамилию",
  FormMiddleName: "Отечество",
  FormMiddleNamePlace: "Введите Ваше отечество",
  FormBirthDate: "Дата рождения",
  FormGender: "Пол",
  FormMale: "Мужчина",
  FormFemale: "Женщина",
  FormUsername: "Имя пользователя",
  FormUsernamePlace: "Введите имя пользователя",
  FormPassword: "Пароль",
  FormPasswordPlace: "Введите Ваш пароль",
  FormPhone: "Номер телефона",
  FormInnerNum: "Внутренний номер",
  FormPhoneInfoService: "Информационная служба",
  FormPhoneHelp: "Телефон доверия",
  FormPhoneContact: "Контактный телефон",
  FormPhoneInfoServicePlace: "Введите номер справочной службы",
  FormchancelleryPlace: "Введите канцелярия",
  FormDepartment: "Введите Отдел контроля",
  FormProfession: "Профессия",
  FormProfessionPlace: "Профессия",
  FormRole: " Роль",
  FormBirthPlace: "Место рождения",
  FormBirthPlacePlaceholder: "Введите Ваше место рождения",
  FormWorkPlace: "Место работы",
  FormWorkPlacePlaceholder: "Введите Ваше место работы",
  FormNat: "Национальность",
  FormNatPlace: "Millatingizni kiriting",
  FormEdu: "Образование",
  FormEduPlace: "Введите свое образование",
  FormUni: "University",
  FormUniPlace: "Enter Your University",
  FormSpec: "Специальность",
  FormSpecPlace: "Введите свою специальность",
  FormAcademDeg: "Академическая степень",
  FormAcademDegPlace: "Напишите вашу академическую степень",
  FormAcademtit: "Академическая звание",
  FormAcademtitPlace: "Напишите вашу академическое звание",
  FormBodies: "Избранных органов государственной власти",
  FormOkrug: "Округ",
  FormParty: "Партия",
  FormPartyPlace: "Напишите партию",
  FormFraction: "Фракция",
  FormCommittee: "Комитет",
  // Menu
  MenuTitle: "Название меню:",
  MenuTitlePlace: "Введите название меню",
  MenuGroup: "Группа:",
  MenuGroupPlace: "Введите группу",
  MenuParentName: "Выберите родительский меню:",
  MenuPosition: "Позиция",
  MenIsBlog: "Блог",
  // Tags
  TagsColTitle: "Название",
  TagsColNumber: "Номер",
  // Table
  TableTitle: "Название",
  TableContent: "Содержимое",
  TableShortContent: "Краткое содержание",
  TablePhoto: "Фото",
  TablePhotos: "Изображения",
  TableDoc: "Документы",
  TableHead: "Заголовок",
  TableDate: "Дата публикации",
  TableCreatedDate: "Дата создания",
  TableLastUpdatedDate: "Дата обновления",
  TableAction: "Действие",
  Lang: "Языки",
  Content: "Контент",
  IsActive: "Активен ?",
  Active: "Актив",
  Cancel: "Отмена",
  Save: "Сохранить",
  SaveAndExit: "Сохранить и выйти",
  FileDoc: "Файлы документы",
  FileSelect: "Выберите файл",
  LangContent: "Языки контента",
  LangTitle: "Языки заголовок",
  File: "Файлы",
  Created: "Создано",
  Create: "Создать",
  AdressPlac: "Введите адрес",
  Source: "Источник",
  SourcePlac: "Введите источник",
  Deputy: "Депутат ",
  Video: "Видео",
  selected: "выбранное",
  InputText: "Введите текст здесь",
  Leader: "Лидер",
  // Committe
  CommitTitle: "Название комитета",
  CommitTitlePlac: "Введите название комитета",
  CommitEdit: "Редактировать комитет",
  Chairman: "Председатель",
  SubChairman: "Заместитель председателя",
  Status: "Статус",
  Url: "URL адрес",
  EnterTheTitle: "Введите название",
  EnterStatus: "Введите статус",
  EnterUrl: "Введите URL адрес",
  ListBroadcast: "Список прямых эфиров пуст!",
  LawsEmpty: "Список законов пуст!",
  TranslateCopyTo: "Перевести копию на",
  EnterTitle: "Введите заголовок",
  DateOfPublication: "Дата публикации",
  Close: "Ёпиш",
  AreasEmpty: "Список областей пуст!",
  AddArea: "Добавить область",
  Region: "Область",
  CountiesEmpty: "Список округов пуст!",
  AddCommissions: "Добавить комиссии",
  Loading: "Идет загрузка...",
  UploadDocToServer: "Загрузить документы на сервер",
  Downloads: "Файлы для загрузки",
  Consider: "Рассмотреть",
  ToAnswer: "Ответить",
  ViewAnswer: "Посмотреть ответ",
  Adress: "Адресс",
  EnterAdress: "Введите адресс",
  SenderStatus: "Статус отправителя",
  ToWhom: "Кому",
  Passport: "Паспорт",
  PassportNumber: "Номер паспорта",
  LastUpdateDate:"Дата последнего обновления",
  Chancery: "Канцелярия",
  EnterChanceryNumb: "Введите номер канцеляри",
  Faks: "Факс",
  EnterFaks: "Введите факс",
  Email: "Эл. почта",
  EnterEmail: "Введите эл.почту",
  Bus: "Автобусы",
  EnterBuses: "Введите номера автобусов",
  MiniBus: "Маршрутки",
  EnterMinibuses: "Введите номера маршруток",
  Metro: "Метро",
  EnterMetro: "Введите станция метро",
  ReferencePoint: "Ориентир",
  EnterLandmark: "Введите ориентир",
  WorkingTime: "Рабочая время",
  Sum: "Сумма",
  Value: "Значение",
  Logo: "Логотип",
  Icon: "Иконка",
  Question: "Вопрос",
  Questions: "Вопроы",
  QuestionTheme: "Тема вопроса",
  Answer: "Ответ",
  Author: "Автор",
  ViewsCount: "Просмотры",
  OnSlider: "На главную",
  Comments: "Комментарии",
  JobTitle: "Должность",
  Department: "Департамент",
  HasExpired: "Утратил силу",
  AcceptDate: "Дата принятие",
  Order: "Порядок",
  Activity: "Деятельность",
  pinfl: "Персональный идентификационный номер (ПИН)",
  cert_number: "Номер сертификата",
  licence_number:"Номер лицензии",
  cert_registered_date:"Дата регистрации сертификата",
  cert_validity_date: "Дата действия сертификата",
  licence_registered_date: "Дата регистрации лицензии",
}

export default ru
